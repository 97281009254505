<div class="fixed top-0 left-0 w-full z-50">
  <div class="container">
    <nav
      class="
          text-gray text-sm
          md:text-xl
          flex flex-row
          items-center
          bg-background
          py-4
        "
    >
      <div class="flex flex-row gap-x-2 lg:gap-x-4 flex-1">
        <a href="/">Home</a>
        <a href="/kontakt">Kontakt</a>
        <a href="/documents/stellenausschreibung.pdf" target="blank">Jobs</a>
        <a href="/impressum">Impressum</a>
        <a href="/datenschutz">Datenschutz</a>
      </div>
      <a href="https://www.instagram.com/glanzundgloria_hamburg/">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          class="w-4 h-4 md:w-6 md:h-6"
          viewBox="0 0 24 24"
        >
          <path
            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4H7.6m9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8 1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5 5 5 0 0 1-5 5 5 5 0 0 1-5-5 5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z"
            fill="currentColor"
          />
        </svg>
      </a>
    </nav>
  </div>
</div>
