<script context="module">
  import Navigation from "$lib/Navigation.svelte";
  import "../styles/generated/tailwind.css";
</script>

<svelte:head>
  <title>GLANZ UND GLORIA - Agentur für Kommunikation - Hamburg</title>
</svelte:head>

<main>
  <Navigation />
  <div class="mt-24">
    <slot />
  </div>
</main>
